import { Link } from "@/components/base/link";
import { HorizontalContainer } from "@/components/layout/horizontal-container";
import { VerticalSpacer } from "@/components/layout/vertical-spacer";
import { LogInOrRegisterLinks, LoginLink, RegisterLink } from "@/components/ui/log-in-or-register-links";
import { Text } from "@/components/ui/text";
import {
  bookingErrorsToString,
  eventBookingErrorsToString,
  facilityServiceBookingErrorsToString,
  humanServicesBookingErrorsToString,
  invoiceErrorsToString,
  netsPaymentErrorsToString,
  userCreationErrorsToString,
  valueCardPurchasingErrorsToString,
} from "@/utils/error-code-lookup";
import { KundeserviceRoutes, MyPageRoutes, paths } from "@/utils/paths-utils";
import { BookingType } from "../booking/types";

export enum ErrorMessageFlavour {
  GROUP_ACTIVITY = 0,
  FACILITY_SERVICE = 1,
  HUMAN_SERVICE = 2,
  INVOICE = 3,
  VALUECARD = 4,
  EVENT = 5,
  CREATE_USER = 6,
  NETS_PAYMENT = 7,
  CREATE_MEMBERSHIP = 8,
}

interface ErrorMessageProps {
  error: string;
  errorMessageFlavour: ErrorMessageFlavour;
}

export const ErrorMessage = ({ error, errorMessageFlavour }: ErrorMessageProps) => {
  const humanErrorMessageWithFlavour = getErrorMessage(error, errorMessageFlavour);
  return (
    <HorizontalContainer horizontalPlacement="left" verticalPlacement="center">
      <HorizontalContainer noWrap={true}>
        <Text color="red" size="small" style="italic" value={humanErrorMessageWithFlavour} />
        <VerticalSpacer size="x-small" />
      </HorizontalContainer>

      {getSpecificErrorDisplay(error)}
    </HorizontalContainer>
  );
};

interface ErrorMessageStringProps {
  errorString: string;
}

export const ErrorMessageString = ({ errorString }: ErrorMessageStringProps) => {
  return <Text color="red" size="small" style="italic" value={errorString} />;
};

const getErrorMessage = (error: string, errorMessageFlavour: ErrorMessageFlavour) => {
  switch (errorMessageFlavour) {
    case ErrorMessageFlavour.GROUP_ACTIVITY:
      return bookingErrorsToString(error);
    case ErrorMessageFlavour.FACILITY_SERVICE:
      return facilityServiceBookingErrorsToString(error);
    case ErrorMessageFlavour.HUMAN_SERVICE:
      return humanServicesBookingErrorsToString(error);
    case ErrorMessageFlavour.VALUECARD:
      return valueCardPurchasingErrorsToString(error);
    case ErrorMessageFlavour.EVENT:
      return eventBookingErrorsToString(error);
    case ErrorMessageFlavour.CREATE_USER:
      return userCreationErrorsToString(error);
    case ErrorMessageFlavour.NETS_PAYMENT:
      return netsPaymentErrorsToString(error);
    case ErrorMessageFlavour.INVOICE:
      return invoiceErrorsToString(error);
    default:
      return bookingErrorsToString(error);
  }
};

const getSpecificErrorDisplay = (error: string) => {
  if (
    error === "Access denied! You need to be authenticated to perform this action!" ||
    error === "You are not authorized to perform this action" ||
    error === "UNAUTHENTICATED"
  ) {
    return <LogInOrRegisterLinks />;
  }
  if (
    error === "BOOKING_REQUIRES_MEMBERSHIP" ||
    error === "BOOKING_NOT_ALLOWED" ||
    error === "BOOK_SERVICE_INSUFFICIENT_RIGHTS"
  ) {
    return (
      <>
        <RegisterLink />
        <VerticalSpacer size="x-small" />
        <Link href={paths.minSide(MyPageRoutes.MEDLEMSKAP)}>
          <Text size="small" style="italic" underline={true} value="Oppgrader medlemskap" />
        </Link>
      </>
    );
  }
  if (error === "EMAIL_ALREADY_EXISTS") {
    return <LoginLink />;
  }
  if (error === "BOOKING_TERMS_NOT_ACCEPTED") {
    return (
      <Link href={paths.minSide(MyPageRoutes.SAMTYKKER)}>
        <Text size="small" style="italic" underline={true} value="Oppdater samtykker" />
      </Link>
    );
  }
  if (
    error === "ALREADY_BOOKED" ||
    error === "TOO_MANY_CONCURRENT_BOOKINGS" ||
    error === "BOOKING_CLASHES_WITH_OTHER_BOOKING"
  ) {
    return (
      <Link href={paths.minSide(MyPageRoutes.MYBOOKINGS)}>
        <Text size="small" style="italic" underline={true} value="Se dine bookinger" />
      </Link>
    );
  }
  if (
    error === "TOO_LATE_TO_CANCEL" ||
    error === "TOO_LATE_TO_BOOK" ||
    error === "TOO_LATE_TO_BOOK_WAITING_LIST" ||
    error === "TOO_LATE_TO_BOOK_WAITINGLIST"
  ) {
    return (
      <Link href={paths.kundeservice(KundeserviceRoutes.VILKAAR_OG_REGLER_BOOKINGREGLER)}>
        <Text size="small" style="italic" underline={true} value="Les bookingregler" />
      </Link>
    );
  }

  return <></>;
};

export const bookingTypeToErrorMessageFlavour = (bookingType: BookingType) => {
  switch (bookingType) {
    case BookingType.GRUPPETIME:
      return ErrorMessageFlavour.GROUP_ACTIVITY;
    case BookingType.CAGEBALL:
      return ErrorMessageFlavour.FACILITY_SERVICE;
    case BookingType.GOLFSIMULATOR:
      return ErrorMessageFlavour.FACILITY_SERVICE;
    case BookingType.SQUASH:
      return ErrorMessageFlavour.FACILITY_SERVICE;
    case BookingType.VEILEDNING:
      return ErrorMessageFlavour.HUMAN_SERVICE;
    case BookingType.BARNEPASS:
      return ErrorMessageFlavour.HUMAN_SERVICE;
    case BookingType.TRENINGSTESTER:
      return ErrorMessageFlavour.HUMAN_SERVICE;
    case BookingType.KLIPPEKORT:
      return ErrorMessageFlavour.VALUECARD;
    case BookingType.PT:
      return ErrorMessageFlavour.HUMAN_SERVICE;
    default:
      return ErrorMessageFlavour.GROUP_ACTIVITY;
  }
};
