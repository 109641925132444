import { useSession } from "next-auth/react";

import { ROOT_URL, paths } from "@/utils/paths-utils";
import { setQueryStringValues } from "@/utils/query-string-utils";
import { usePathname, useSearchParams } from "next/navigation";
import { Link } from "../base/link";
import { HorizontalContainer } from "../layout/horizontal-container";
import { VerticalSpacer } from "../layout/vertical-spacer";
import { Text } from "./text";

export const LogInOrRegisterLinks = () => {
  const session = useSession();

  if (session.status === "authenticated") {
    return <></>;
  }

  return (
    <HorizontalContainer>
      <RegisterLink />

      <VerticalSpacer size="x-small" />

      <LoginLink />
    </HorizontalContainer>
  );
};

export const RegisterLink = () => {
  return (
    <Link href={paths.bliMedlem}>
      <Text size="small" style="italic" underline={true} value="Bli medlem" />
    </Link>
  );
};

interface LoginLinkProps {
  notItalic?: boolean;
  callback?: boolean;
}

export const LoginLink = ({ notItalic, callback = true }: LoginLinkProps) => {
  const session = useSession();
  const path = usePathname();
  const searchParams = useSearchParams();

  if (session.status === "authenticated") {
    return <></>;
  }

  const linkHref = callback
    ? `/logg-inn?callbackUrl=${encodeURIComponent(`${ROOT_URL}${path}?${setQueryStringValues([], searchParams)}`)}`
    : "/logg-inn";

  return (
    <Link href={linkHref}>
      <Text size="small" style={notItalic ? undefined : "italic"} underline={true} value="Logg inn" />
    </Link>
  );
};
