import { Logger } from "@/utils/logger";

const errorMessages = new Map<string, string>([
  // Documentation for the numeric error codes: https://brpsystems.atlassian.net/wiki/spaces/DOK22/pages/2332524686/F+renklad+betalning+via+webservice+i+BRP+Online

  ["9", "Innstillinger i BRP som kreves for angitt betalingstjenesteleverandør og betalingsmåte mangler."],
  ["10", "Betalingsmåte-id mangler i anropet eller angitt betalingsmåte-id eksisterer ikke."],
  ["11", "Internett-kassen mangler på senteret som ordren er laget på (konfigurasjon)."],
  ["12", "Bestillings-id mangler i anropet eller angitt bestillings-id eksisterer ikke."],
  ["13", "Betalingsmåten er ikke konfigurert for internett-kjøp (konfigurasjon)."],
  ["14", "Betalingsmåten støttes ikke (konfigurasjon)."],
  ["15", "Betalingsmåten støtter ikke tilbakevendende kortbetaling."],
  ["100", "Bestillingen mangler senterthørighet."],
  ["101", "Bestillingen er allerede belastet."],
  ["102", "Bestillingens pris er null eller negativ."],
  ["103", "Bestiller mangler på bestillingen."],
  ["104", "Personnummer mangler, noe som kreves for aktuell betalingsmetode."],
  ["105", "E-postadresse eller postadresse kreves for aktuell betalingsmetode."],
  ["106", "E-postadresse kreves for aktuell betalingsmetode."],
  ["107", "Postadresse kreves for aktuell betalingsmetode."],
  [
    "108",
    "Bestillingen ble ikke tillatt å betale fordi den inneholder produkter som ikke er tillatt av betalingsalternativene (se innstillinger).",
  ],
  [
    "109",
    "Bestillingen ble ikke tillatt å bekrefte fordi den inneholder produkter som ikke er tillatt av betalingsalternativene (se innstillinger).",
  ],
  ["110", "Bestilleren prøver å betale med en organisasjonskonto, men har ingen organisasjon koblet i BRP."],
  ["111", "En eller flere produkter har en deltakerliste der deltakerinformasjon ikke er angitt."],
  ["200", "Betalingsleverandørens egen validering går ikke gjennom."],
  ["201", "Betalingen ble ikke fullført (mislyktes eller ble avbrutt)."],
  ["202", "Beløp på bestilling og gjennomført betaling stemmer ikke, transaksjonen avbrytes."],
  ["203", "Bestillingen er fjernet eller på annen måte blitt feilaktig mens betalingen ble gjennomført."],
  ["204", "Uventet svar fra betalingstjenesteleverandøren."],
  ["206", "Kortgodkjenning kunne ikke opprettes."],
  ["207", "Verdikort, som er reservert for betaling, har endret seg mens betalingen ble gjennomført."],
  ["208", "Belastning av bestillingen mislyktes."],
  ["300", "Faktura mangler."],
  ["301", "Faktura mangler sentertilhørighet."],
  ["302", "Fakturaens beløp er null eller negativt."],
  ["303", "Fakturaen er fjernet eller på annen måde blitt feilaktig mens betalingen ble gjennomført."],
  ["304", "Faktura mangler referanse."],
  ["305", "Faktura kan ikke opprettes for abonnementet."],
  ["400", "Feil hash."],
  ["500", "Kundekontoen har ikke nok kreditt til å dekke bestillingen."],
  ["501", "Kundekontoen kan ikke brukes fordi kunden ikke er gammel nok."],
  ["502", "Kundekontoen kan ikke brukes fordi det mangler godkjenning for automatisk trekk."],
  ["503", "Kundekontoen kan ikke brukes fordi det er en ubetalt faktura."],
  ["504", "Kundekontoen kan ikke brukes fordi kunden mangler en gyldig e-postadresse."],
  ["505", "Kundekontoen kan ikke brukes fordi kunden ikke tillater det."],
  ["506", "Kundekontoen kan ikke brukes fordi kunden mangler et Autogiro-abonnement."],
  ["902", "Swish: Det angitte telefonnummeret er feil."],
]);

const logNetsDebugStatement = (error: string) => {
  const message = errorMessages.get(error);
  if (message === undefined) {
    Logger.error(`Unknown error from brp: ${error}, message: Ukjent feilkode.`);
  } else {
    Logger.error(`Unexpected error from brp: ${error}, message: ${message}`);
  }
};
export const netsPaymentErrorsToString = (error: string) => {
  if (error === "205") {
    return "Betalingen ble avbrutt.";
  }
  logNetsDebugStatement(error);
  return "Noe gikk galt, prøv igjen om noen minutter. Kontakt kundeservice hvis feilen vedvarer.";
};

export const bookingErrorsToString = (error: string) => {
  // Documentation for the uppercase string error codes below: https://3t.brpsystems.com/brponline/external/documentation/api3?key=7fbc7dd2c5f944bb87d369d4826d1e09#_error_codes
  switch (error) {
    case "Access denied! You need to be authenticated to perform this action!":
      return "Du må logge inn for å booke denne tjenesten.";
    case "You are not authorized to perform this action":
      return "Du må logge inn for å booke denne tjenesten.";
    case "UNAUTHENTICATED":
      return "Du må logge inn for å booke denne tjenesten.";
    case "BOOKING_CLASHES_WITH_OTHER_BOOKING":
      return "Du kan ikke booke flere timer i samme tidsrom.";
    case "BOOKING_REQUIRES_MEMBERSHIP":
      return "Du må ha et aktivt medlemskap for å booke timer.";
    case "TOO_LATE_TO_CANCEL":
      return "Fristen for å avbooke denne timen har gått ut.";
    case "TOO_MANY_CONCURRENT_BOOKINGS":
      return "Du har nådd maksgrensen for bookede timer.";
    case "TOO_LATE_TO_BOOK":
      return "Fristen for å booke denne timen har gått ut.";
    case "TOO_LATE_TO_BOOK_WAITING_LIST":
    case "TOO_LATE_TO_BOOK_WAITINGLIST":
      return "Fristen for å booke venteliste har gått ut.";
    case "TOO_EARLY_TO_BOOK":
      return "Denne timen kan dessverre ikke bookes enda";
    case "BOOKING_RULE_PREVENTS_BOOKING": {
      Logger.error(`User tried to book gruppetime in violation with the booking rules: ${error}`);
      return "Denne timen kan ikke bookes.";
    }
    case "NO_FREE_RESOURCES_AVAILABLE": {
      Logger.error(`User tried to book gruppetime/bane that is already booked ${error}`);
      return "Bane er allerede booket.";
    }
    case "RESOURCE_DOES_NOT_BELONG_TO_BUSINESS_UNIT":
      return "Du kan bare booke fra et senter om gangen.";
    case "BOOK_SERVICE_INSUFFICIENT_RIGHTS":
      return "Du må logge inn og ha et aktivt medlemskap for å booke squashbaner.";
    case "BOOKING_TERMS_NOT_ACCEPTED":
      return "Du må godkjenne bookingvilkår før du kan booke.";
    case "BOOKING_NOT_ALLOWED":
      return "Du har ikke rettigheter til å booke denne timen.";
    case "ALREADY_BOOKED":
      return "Du har allerede booket denne timen.";
    case "CANNOT_CANCEL_BOOKING":
      return "Ikke mulig å avbooke. Ta kontakt med kundeservice ved behov.";
    case "CANNOT_BOOK_CANCELLED_ACTIVITY":
      return "Denne timen kan ikke bookes fordi den er avlyst.";
    default: {
      Logger.error(`Unknown error when booking: ${error}`);
      return "Noe gikk galt, prøv igjen om noen minutter. Kontakt kundeservice hvis feilen vedvarer.";
    }
  }
};

export const facilityServiceBookingErrorsToString = (error: string) => {
  switch (error) {
    case "Access denied! You need to be authenticated to perform this action!":
      return "Du må logge inn for å booke denne tjenesten.";
    case "You are not authorized to perform this action":
      return "Du må logge inn for å booke denne tjenesten.";
    case "UNAUTHENTICATED":
      return "Du må logge inn for å booke denne tjenesten.";
    case "BOOKING_CLASHES_WITH_OTHER_BOOKING":
      return "Du kan ikke booke flere baner i samme tidsrom.";
    case "BOOKING_REQUIRES_MEMBERSHIP":
      return "Du må ha et aktivt medlemskap for å booke denne tjenesten.";
    case "TOO_LATE_TO_CANCEL":
      return "Fristen for å avbooke denne banen har gått ut.";
    case "TOO_MANY_CONCURRENT_BOOKINGS":
      return "Du har nådd maksgrensen for bookede baner.";
    case "TOO_LATE_TO_BOOK":
      return "Fristen for å booke denne banen har gått ut.";
    case "TOO_LATE_TO_BOOK_WAITING_LIST":
    case "TOO_LATE_TO_BOOK_WAITINGLIST":
      return "Fristen for å booke venteliste har gått ut.";
    case "TOO_EARLY_TO_BOOK":
      return "Du kan ikke booke denne banen lenger fram enn 7 dager.";
    case "BOOKING_RULE_PREVENTS_BOOKING": {
      Logger.error(`User tried to book facility in violation with the booking rules: ${error}`);
      return "Denne banen kan ikke bookes.";
    }
    case "NO_FREE_RESOURCES_AVAILABLE": {
      Logger.error(`User tried to book facility that is already booked ${error}`);
      return "Dette tidspunktet er allerede booket.";
    }
    case "RESOURCE_DOES_NOT_BELONG_TO_BUSINESS_UNIT":
      return "Du kan bare booke fra et senter om gangen.";
    case "BOOK_SERVICE_INSUFFICIENT_RIGHTS":
      return "Du må logge inn og ha et aktivt medlemskap for å booke denne tjenesten.";
    case "BOOKING_TERMS_NOT_ACCEPTED":
      return "Du må godkjenne bookingvilkår før du kan booke.";
    case "ONLY_SINGLE_CENTER_BOOKINGS":
      return "Du kan bare booke fra et senter om gangen.";
    case "SQUASH_COURT_LIMIT_REACHED":
      return "Du kan bare ha to aktive squashbanebookinger.";
    case "BOOKING_NOT_ALLOWED":
      return "Du har ikke rettigheter til å booke denne banen.";
    case "ALREADY_BOOKED":
      return "Du har allerede booket denne banen.";
    case "CANNOT_CANCEL_BOOKING":
      return "Ikke mulig å avbooke. Ta kontakt med kundeservice ved behov.";
    case "CANNOT_BOOK_CANCELLED_ACTIVITY":
      return "Denne banen kan ikke bookes fordi banebookingen er stengt.";
    default: {
      Logger.error(`Unknown error when booking facility: ${error}`);
      return "Noe gikk galt, prøv igjen om noen minutter. Kontakt kundeservice hvis feilen vedvarer.";
    }
  }
};

export const humanServicesBookingErrorsToString = (error: string) => {
  switch (error) {
    case "Access denied! You need to be authenticated to perform this action!":
      return "Du må logge inn for å booke denne tjenesten.";
    case "You are not authorized to perform this action":
      return "Du må logge inn for å booke denne tjenesten.";
    case "UNAUTHENTICATED":
      return "Du må logge inn for å booke denne tjenesten.";
    case "BOOKING_CLASHES_WITH_OTHER_BOOKING":
      return "Du kan ikke booke flere tjenester i samme tidsrom.";
    case "BOOKING_REQUIRES_MEMBERSHIP":
      return "Du må ha et aktivt medlemskap for å booke denne tjenesten.";
    case "TOO_LATE_TO_CANCEL":
      return "Fristen for å avbooke denne tjenesten har gått ut.";
    case "TOO_MANY_CONCURRENT_BOOKINGS":
      return "Du har nådd maksgrensen for antall bookede tjenester.";
    case "TOO_LATE_TO_BOOK":
      return "Fristen for å booke denne tjenesten har gått ut.";
    case "TOO_LATE_TO_BOOK_WAITING_LIST":
    case "TOO_LATE_TO_BOOK_WAITINGLIST":
      return "Fristen for å booke venteliste har gått ut.";
    case "TOO_EARLY_TO_BOOK":
      return "Du kan ikke booke denne tjenesten lenger fram enn 7 dager.";
    case "BOOKING_RULE_PREVENTS_BOOKING": {
      Logger.error(`User tried to book human resource in violation with the booking rules: ${error}`);
      return "Denne timen kan ikke bookes.";
    }
    case "NO_FREE_RESOURCES_AVAILABLE": {
      Logger.error(`User tried to book human service that is already booked ${error}`);
      return "Dette tidspunktet er allerede booket.";
    }
    case "RESOURCE_DOES_NOT_BELONG_TO_BUSINESS_UNIT":
      return "Du kan bare booke fra et senter om gangen.";
    case "BOOK_SERVICE_INSUFFICIENT_RIGHTS":
      return "Du må logge inn og ha et aktivt medlemskap for å booke denne tjenesten.";
    case "BOOKING_TERMS_NOT_ACCEPTED":
      return "Du må godkjenne bookingvilkår før du kan booke.";
    case "BOOKING_NOT_ALLOWED":
      return "Du har ikke rettigheter til å booke denne timen.";
    case "ALREADY_BOOKED":
      return "Du har allerede booket denne timen.";
    case "CANNOT_CANCEL_BOOKING":
      return "Ikke mulig å avbooke. Ta kontakt med kundeservice ved behov.";
    case "CANNOT_BOOK_CANCELLED_ACTIVITY":
      return "Denne timen kan ikke bookes fordi den er avlyst.";
    default: {
      Logger.error(`Unknown error when booking human service: ${error}`);
      return "Noe gikk galt, prøv igjen om noen minutter. Kontakt kundeservice hvis feilen vedvarer.";
    }
  }
};

export const valueCardPurchasingErrorsToString = (error: string) => {
  switch (error) {
    case "Access denied! You need to be authenticated to perform this action!":
      return "Du må logge inn for å gjennomføre kjøpet.";
    case "You are not authorized to perform this action":
      return "Du må logge inn for å gjennomføre kjøpet.";
    case "UNAUTHENTICATED":
      return "Du må logge inn for å gjennomføre kjøpet.";
    case "BOOKING_REQUIRES_MEMBERSHIP": {
      Logger.error(`User was asked to purchase membership to purchase value card: ${error}`);
      return "Du må ha et aktivt medlemskap for å gjennomføre kjøpet.";
    }
    case "BOOK_SERVICE_INSUFFICIENT_RIGHTS": {
      Logger.error(`User was asked to purchase membership to purchase value card: ${error}`);
      return "Du må logge inn og ha et aktivt medlemskap for å gjennomføre kjøpet.";
    }
    case "BOOKING_TERMS_NOT_ACCEPTED": {
      Logger.error(`User was asked to accept booking terms to purchase value card: ${error}`);
      return "Du må godkjenne bookingvilkår for å gjennomføre kjøpet.";
    }
    case "BOOKING_NOT_ALLOWED": {
      Logger.error(`User was asked to purchase membership to purchase value card: ${error}`);
      return "Du har ikke rettigheter til å gjennomføre kjøpet.";
    }
    default: {
      Logger.error(`Unknown error when purchasing valueCard: ${error}`);
      return "Noe gikk galt, prøv igjen om noen minutter. Kontakt kundeservice hvis feilen vedvarer.";
    }
  }
};

export const eventBookingErrorsToString = (error: string) => {
  switch (error) {
    case "Access denied! You need to be authenticated to perform this action!":
      return "Du må logge inn for å booke dette arrangementet.";
    case "You are not authorized to perform this action":
      return "Du må logge inn for å booke dette arrangementet.";
    case "UNAUTHENTICATED":
      return "Du må logge inn for å booke dette arrangementet.";
    case "BOOKING_CLASHES_WITH_OTHER_BOOKING":
      return "Du kan ikke booke flere arrangement i samme tidsrom.";
    case "BOOKING_REQUIRES_MEMBERSHIP":
      return "Du må ha et aktivt medlemskap for å booke dette arrangementet.";
    case "TOO_LATE_TO_CANCEL":
      return "Fristen for å avbooke dette arrangementet har gått ut.";
    case "TOO_MANY_CONCURRENT_BOOKINGS":
      return "Du har nådd maksgrensen for antall bookede arrangement.";
    case "TOO_LATE_TO_BOOK":
      return "Fristen for å booke dette arrangementet har gått ut.";
    case "TOO_LATE_TO_BOOK_WAITING_LIST":
    case "TOO_LATE_TO_BOOK_WAITINGLIST":
      return "Fristen for å booke venteliste har gått ut.";
    case "TOO_EARLY_TO_BOOK":
      return "Dette arrangementet har ikke åpnet for booking ennå.";
    case "BOOKING_RULE_PREVENTS_BOOKING": {
      Logger.error(`User tried to book event in violation with the booking rules: ${error}`);
      return "Dette arrangementet kan ikke bookes.";
    }
    case "NO_FREE_RESOURCES_AVAILABLE":
      return "Dette tidspunktet er allerede booket.";
    case "RESOURCE_DOES_NOT_BELONG_TO_BUSINESS_UNIT":
      return "Du kan bare booke fra et senter om gangen.";
    case "BOOK_SERVICE_INSUFFICIENT_RIGHTS":
      return "Du må logge inn og ha et aktivt medlemskap for å booke dette arrangementet.";
    case "BOOKING_TERMS_NOT_ACCEPTED":
      return "Du må godkjenne bookingvilkår før du kan booke.";
    case "BOOKING_NOT_ALLOWED":
      return "Du har ikke rettigheter til å booke arrangementet.";
    case "ALREADY_BOOKED":
      return "Du har allerede booket dette arrangementet.";
    case "BOOK_EVENT_INSUFFICIENT_SLOTS":
      return "Dette arrangmentet er fullbooket.";
    case "CANNOT_CANCEL_BOOKING":
      return "Ikke mulig å avbooke. Ta kontakt med kundeservice ved behov.";
    case "CANNOT_BOOK_CANCELLED_ACTIVITY":
      return "Dette arrangementet kan ikke bookes fordi det er avlyst.";
    default: {
      Logger.error(`Unknown error when booking event: ${error}`);
      return "Noe gikk galt, prøv igjen om noen minutter. Kontakt kundeservice hvis feilen vedvarer.";
    }
  }
};

export enum UserCreationErrorCode {
  PHONENUMBER_INVALID = "PHONENUMBER_INVALID",
  EMAIL_ALREADY_EXISTS = "EMAIL_ALREADY_EXISTS",
}

export const isUserCreationErrorCode = (value: string): value is UserCreationErrorCode => {
  return Object.values(UserCreationErrorCode).includes(value as UserCreationErrorCode);
};

export const userCreationErrorsToString = (error: UserCreationErrorCode | string) => {
  switch (error) {
    case UserCreationErrorCode.PHONENUMBER_INVALID:
      return "Ugyldig telefonnummer";
    case UserCreationErrorCode.EMAIL_ALREADY_EXISTS:
      return "E-postadressen er allerede i bruk.";
    default: {
      Logger.error(`Unknown error when creating user: ${error}`);
      return "Noe gikk galt, prøv igjen om noen minutter. Kontakt kundeservice hvis feilen vedvarer.";
    }
  }
};

export const invoiceErrorsToString = (error: string) => {
  switch (error) {
    case "CANNOT_CREATE_PDF":
      return "Kunne ikke opprette pdf. Vennligst prøv igjen senere.";
    default: {
      Logger.error(`Unknown error when dealing with invoices: ${error}`);
      return "Noe gikk galt, prøv igjen om noen minutter. Kontakt kundeservice hvis feilen vedvarer.";
    }
  }
};

export const paymentCertificateErrorsToString = (error: string) => {
  switch (error) {
    case "COULD_NOT_SEND_PAYMENT_CERTIFICATE_BY_EMAIL":
    case "CANNOT_GENERATE_PAYMENT_CERTIFICATE":
      return "Kunne ikke opprette fakturaoversikt. Kontakt kundeservice hvis feilen vedvarer.";
    default: {
      Logger.error(`Unknown error when generating payment certificate: ${error}`);
      return "Noe gikk galt, prøv igjen om noen minutter. Kontakt kundeservice hvis feilen vedvarer.";
    }
  }
};
